<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody
            v-for="item, key in coloresTipoSubfamilia"
            :key="key"
          >
            <tr>
              <td>
                <v-chip
                  :color="item.color"
                  size="small"
                  dark
                >
                  <span>ABC123</span>
                </v-chip>
              </td>
              <td>
                {{ item.tsubfamilia_desc }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider />
      <span>Nota: la información se muestra dependiendo la configuración del tipo de subsistema, de los campos "Ver número de elemento", "Ver número de etiqueta" o "Ver número de serie"</span>
    </template>
  </b10-legend>
</template>
<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    coloresTipoSubfamilia: {
      type: Array,
      default: () => [],
    },
  },
}

</script>
